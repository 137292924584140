import React from 'react'

import NewProducts from '../../../react4xp/components/NewProducts'

function NewProductsPart({ products, title, bottomLink }) {
    return (
        <NewProducts
            section={{
                bgColor: 'white',
                title: title,
                paddingBottom: 'none'
            }}
            products={products}
            bottomLink={{
                href: bottomLink.url,
                external: bottomLink.isExternal,
                text: bottomLink.text
            }}
        />
    )
}

export default (props) => <NewProductsPart {...props} />
